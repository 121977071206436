import React from "react";
import styled from "styled-components";
import { ParallaxBanner } from "react-scroll-parallax";
import { Headline } from "./type";
import { GridWrapper } from "./layout-components";
import Box from "./john-motion/motion-box";
import AnimatedText from "./john-motion/motion-headline";
import ImageBox from "./john-motion/motion-box/image-box";
import { clampBuilder } from "../functions/util";
import Button from "./globals/button";
import SvgLoader from "./svg-loader";

const BannerWrapper = styled(GridWrapper)`
  position: relative;
  margin: 3rem auto;
`;

const Banner = styled(Box)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(3, auto);
  padding: 4vw;
  min-height: 24rem;
  grid-area: 1 / start / 2 / end;
  border-radius: calc(1rem + 2.5vw);
  border: 3px solid ${({ theme }) => theme.colors.golden};
  align-items: center;
  overflow: hidden;

  .parallax-banner {
    border-radius: calc(0.8rem + 2.5vw);
    overflow: hidden;
  }

  .image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: -1;
  }

  .image {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .h1 {
    margin: 0;
    font-size: ${clampBuilder(70 / 16, 320 / 16, 375, 2560)};
    line-height: 1;
    letter-spacing: -0.025em;
    margin-left: -0.1625em;
  }

  .h6 {
    margin: 0;
  }

  .large {
    display: inline-grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;

    &__for {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    &__classy {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    }
    &__people {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
  }

  .mini {
    display: none;

    @media only screen and ${({ theme }) => theme.device.tablet} {
      display: inline-grid;
    }

    &__mid-century {
      grid-area: 2 / 1 / 3 / 2;
    }
    &__historic {
      grid-area: 1 / 5 / 2 / 6;
      justify-self: end;
    }
  }

  .cta {
    grid-column: 1 / 5;
    grid-row: 4 / 5;
    justify-self: end;
    align-self: end;

    @media only screen and ${({ theme }) => theme.device.tablet} {
      justify-self: end;
      align-self: end;
      grid-column: 5 / 6;
      grid-row: 3 / 4;
    }
  }

  .star {
    grid-area: 2 / 5 / 3 / 6;
    justify-self: end;
    align-self: center;
  }
`;

export default function BannerClassy({
  classy_cta_image,
  classy_cta_action_text,
  classy_cta_action,
}) {
  const transitionOverrides = {
    when: "afterChildren",
    delay: 0.5,
  };
  return (
    <BannerWrapper>
      <Banner>
        <Headline tag="p" size="h1 large large__for">
          <AnimatedText
            text="For"
            preAnimate={{ x: 20, y: 20 }}
            transitionOverrides={transitionOverrides}
          />
        </Headline>
        <Headline tag="p" size="h1 large large__classy">
          <AnimatedText
            text="Classy"
            preAnimate={{ x: 20, y: 20 }}
            transitionOverrides={transitionOverrides}
          />
        </Headline>
        <Headline tag="p" size="h1 large large__people">
          <AnimatedText
            text="People"
            preAnimate={{ x: 20, y: 20 }}
            transitionOverrides={transitionOverrides}
          />
          <Headline tag="span" size="h6 mini">
            <AnimatedText
              text="Classic"
              preAnimate={{ x: 20, y: 20 }}
              transitionOverrides={transitionOverrides}
            />
          </Headline>
        </Headline>
        <Headline tag="p" size="h6 mini mini__mid-century" lineLength="2">
          <AnimatedText
            text="Mid-Century"
            preAnimate={{ x: 20, y: 20 }}
            transitionOverrides={transitionOverrides}
          />
        </Headline>
        <Headline tag="p" size="h6 mini mini__historic">
          <AnimatedText
            text="Historic"
            preAnimate={{ x: 20, y: 20 }}
            transitionOverrides={transitionOverrides}
          />
        </Headline>
        <span className="cta">
          <Button
            to={classy_cta_action || "/inquire"}
            text={classy_cta_action_text || "Let's Chat"}
            color="golden"
          />
        </span>

        <span className="star mini">
          <SvgLoader icon="IconStar" color="golden" />
        </span>
        <ParallaxBanner
          className="image-wrapper"
          layers={[
            {
              speed: -10,
              scale: [1, 1.2],
              children: (
                <ImageBox
                  className="image parallax-banner"
                  hiddenStyles={{ x: 0, y: 0, scale: 1.1 }}
                  transitionOverrides={{ duration: 3 }}
                  fluid={classy_cta_image?.fluid}
                />
              ),
            },
          ]}
        />
      </Banner>
    </BannerWrapper>
  );
}
