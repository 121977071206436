import React from "react";
import { graphql } from "gatsby";

import { HomepageMap } from "../components/homepage-map";
import { HomepageMarqueeNext } from "../components/homepage-marquee-next";
import HomepageSpaces from "../components/homepage-spaces";
import BannerClassy from "../components/banner-classy";
import HomepageOccasions from "../components/homepage-occasions";
import { SlideCarousel } from "../components/slide-carousel";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicHomepage: { data },
    allPrismicTourPage: { edges },
  },
}) {
  return (
    <>
      <HomepageMarqueeNext data={data} />
      <HomepageSpaces spaces={edges} />
      <BannerClassy
        classy_cta_image={data.classy_cta_image}
        classy_cta_action_text={data.classy_cta_action_text}
        classy_cta_action={data.classy_cta_action}
      />
      <HomepageOccasions data={data} />
      <HomepageMap data={data} />
      {data.gallery_images && (
        <SlideCarousel
          title={data.gallery_title}
          slides={data.gallery_images}
        />
      )}
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query HomePage {
    prismicHomepage {
      uid
      id
      data {
        title
        nav_order
        top_level_page
        hide_in_nav
        marquee_headline {
          text
        }
        marquee_action_text
        marquee_action_sub_text
        marquee_action {
          link_type
          target
          url
        }
        marquee_headline_image_aside {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp", q: 25 }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        marquee_headine_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp", q: 25 }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        marquee_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp", q: 25 }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        marquee_action_sub_text_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp", q: 25 }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        marquee_background {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2500
            imgixParams: { fm: "webp", q: 25 }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        classy_cta_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 3000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        classy_cta_action_text
        classy_cta_action
        gallery_headline
        gallery_body {
          raw
        }
        gallery_team_image {
          alt
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 700
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
        gallery_title
        gallery_images {
          image {
            alt
            fluid(
              placeholderImgixParams: { blur: 600, px: 1 }
              maxWidth: 1500
              imgixParams: { auto: "", fm: "webp" }
            ) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        map_action_text
        map_headline
        map_action_link {
          link_type
          target
          url
        }
        map_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
      }
    }

    allPrismicTourPage(
      filter: { data: { nav_order: { gte: 1 } } }
      sort: { fields: data___nav_order, order: ASC }
    ) {
      edges {
        node {
          id
          uid
          data {
            title
            nav_order
            feature_image {
              fluid(
                placeholderImgixParams: { blur: 600, px: 1 }
                maxWidth: 800
                imgixParams: { fm: "webp" }
              ) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
          }
        }
      }
    }
  }
`;
