import React, { useEffect } from "react";
import styled from "styled-components";
import { domAnimation, LazyMotion, m, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { wrapHeadlineInArray } from "../../../functions/util";
import { animation } from "../../../style/theme";

export default function AnimatedText({
  text,
  staggerChildren = 0.1,
  triggerOnce = true,
  preAnimate,
  activeAnimate,
  transitionOverrides,
  ...rest
}) {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce });

  useEffect(() => {
    if (inView) {
      controls.start("active");
    }
    if (!inView) {
      controls.start("pre");
    }
  }, [controls, inView]);

  const textContainerVariants = {
    pre: {},
    active: {
      transition: {
        staggerChildren,
        duration: 2,
        ease: [animation.timingFunction.js],
      },
    },
  };

  const textVariants = {
    pre: {
      opacity: 0,
      rotate: "0deg",
      x: "-1em",
      ...preAnimate,
    },
    active: {
      opacity: 1,
      rotate: "0deg",
      x: 0,
      y: 0,
      ...activeAnimate,
      transition: {
        duration: 0.9,
        ease: [animation.timingFunction.js],
        ...transitionOverrides,
      },
    },
  };

  const array = wrapHeadlineInArray(text);

  return (
    <LazyMotion features={domAnimation}>
      <m.span
        ref={ref}
        variants={textContainerVariants}
        initial="pre"
        animate={controls}
        aria-hidden
        {...rest}
      >
        {array.map((item, i) => (
          <Mask key={i}>
            <m.span variants={textVariants}>{item.text}</m.span>
          </Mask>
        ))}
      </m.span>
    </LazyMotion>
  );
}

const Mask = styled.span`
  position: relative;
  display: inline-block;
  padding: 0 0.1em;
  margin-bottom: -0.4em;

  > span,
  > em {
    display: inline-block;
    position: relative;
    will-change: transform, opacity;
  }
`;
