import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import Button from "./globals/button";
import { Headline } from "./type";
import { Wrapper } from "./layout-components";
import { device } from "../style/theme";
import MapPin from "./svg/pin";
import Box from "./john-motion/motion-box";

const MapSection = styled.section`
  position: relative;
  display: flex;
  border-top: solid 3px ${({ theme }) => theme.colors.golden};
  border-bottom: solid 3px ${({ theme }) => theme.colors.golden};
  padding-top: 8vw;
  padding-bottom: 8vw;
  max-height: 120vw;
  min-height: 120vw;

  @media only screen and ${device.laptop} {
    flex-direction: row;
    max-height: 45vw;
    min-height: 50vmax;
  }
`;

const MapBackground = styled(Img)`
  position: absolute !important;
  top: 0px;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  pointer-events: none;
`;

const MapGrid = styled(Wrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1;

  @media only screen and ${device.laptop} {
    justify-content: space-between;
    /* min-height: 42vw; */
  }
`;

const MapMarker = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;

  svg {
    transform: translate3d(-50%, calc(-50% - 2.5rem), 0);
  }
`;

export function HomepageMap({
  data: { map_headline, map_action_link, map_action_text, map_image },
}) {
  return (
    <Box hiddenStyles={{ x: 0, scale: 1 }}>
      <MapSection id="map">
        <MapGrid>
          <Headline tag="p" size="h6">
            A So. Cal. Destination
          </Headline>
          <Box
            hiddenStyles={{ x: "-4em", y: 0, scale: 1 }}
            transitionOverrides={{ delay: 0 }}
          >
            <Headline tag="p" size="h5">
              <a href={map_action_link.url}>{map_headline}</a>
            </Headline>
            <Box
              hiddenStyles={{ x: "-4em", y: 0, scale: 1 }}
              transitionOverrides={{ delay: 0.3 }}
            >
              <Button
                href={map_action_link.url}
                color="black"
                external={map_action_link.target === "blank"}
                icon="ArrowExternal"
              >
                {map_action_text}
              </Button>
            </Box>
          </Box>
        </MapGrid>
        <MapBackground fluid={map_image.fluid} />
        <MapMarker hiddenStyles={{ x: 0, scale: 1.5, y: "-120%" }}>
          <MapPin />
        </MapMarker>
      </MapSection>
    </Box>
  );
}
