import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { Headline } from "./type";
import { GridWrapper } from "./layout-components";
import AnimatedText from "./john-motion/motion-headline";
import Link from "./john-gatsby-helpers/link";
import Button from "./globals/button";

const SpaceImage = styled.span`
  --space: -6vw;
  content: "";
  display: none;
  position: absolute;
  pointer-events: none;
  width: 20vw;
  height: 13vw;
  border-radius: 13vw;
  overflow: hidden;
  top: 50%;
  right: var(--space);
  transform: translate3d(100%, 0%, 0) scale(0.9);
  transition: opacity ${({ theme }) => theme.animation.duration[200].css}
      ${({ theme }) => theme.animation.timingFunction.css},
    transform ${({ theme }) => theme.animation.duration[200].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  opacity: 0;

  .image {
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css};
  }

  @media only screen and ${({ theme }) => theme.device.tablet} {
    display: block;
  }
`;

const SpaceItem = styled(Link)`
  position: relative;
  transition: opacity ${({ theme }) => theme.animation.duration[300].css}
    ${({ theme }) => theme.animation.timingFunction.css};

  &:nth-child(odd) {
    ${SpaceImage} {
      left: var(--space);
      right: unset;
      transform: translate3d(-100%, 0%, 0) scale(0.9);
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 1.5em;
    left: 0px;
    background: ${({ theme }) => theme.colors.golden};
    transition: transform ${({ theme }) => theme.animation.duration[300].css}
      ${({ theme }) => theme.animation.timingFunction.css};
    transform-origin: right center;
    transform: scaleX(0);
  }

  &:hover {
    ${SpaceImage} {
      opacity: 1;
      transform: translate3d(100%, -50%, 0);

      .image {
        transform: scale(1.25);
      }
    }

    &:nth-child(odd) {
      ${SpaceImage} {
        transform: translate3d(-100%, -50%, 0);
      }
    }

    &:after {
      transform: scaleX(1);
      transform-origin: left center;
    }
  }
`;

function LinkItem({ node: { uid, data } }) {
  return (
    <SpaceItem className="space-item" to={`/tour/${uid}`}>
      <Headline tag="p" size="h2 item-title" aria-label={data.title}>
        <AnimatedText text={data.title} />
      </Headline>
      <SpaceImage>
        <Img className="image" fluid={data.feature_image.fluid} />
      </SpaceImage>
    </SpaceItem>
  );
}

const SpacesSection = styled(GridWrapper)`
  position: relative;
  grid-template-rows: 1fr;
  grid-template-columns: auto;
  justify-items: center;
  margin: 3rem auto;
  padding: 5vw 0 7vw;
  gap: 1rem;

  .h6 {
    margin-bottom: 3rem;
  }
`;

export default function HomepageSpaces({ spaces }) {
  return (
    <SpacesSection>
      <Headline tag="p" size="h6" lineLength={3}>
        Our Unique Spaces
      </Headline>
      {spaces.map(({ node }, i) => (
        <LinkItem key={i} node={node} />
      ))}
      <Button to="/tour" text="Take a tour" color="nude" />
    </SpacesSection>
  );
}
