/**
 * Vendor
 */
import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { ParallaxBanner } from "react-scroll-parallax";

/**
 * Local
 */
import Button from "./globals/button";
import { Headline } from "./type";
import AnimatedText from "./john-motion/motion-headline";
import Box from "./john-motion/motion-box";
import ImageBox from "./john-motion/motion-box/image-box";

/**
 * Functions
 */
import { device } from "../style/theme";
import { clampBuilder, gtagClickEvent } from "../functions/util";
import SvgLoader from "./svg-loader";
import { GridWrapper } from "./layout-components";

const MarqueeSection = styled.section`
  /* padding-top: 5rem; */
  padding-bottom: 4vw;
  position: relative;

  @media only screen and ${device.laptop} {
    /* padding-top: 11rem; */
    flex-direction: row;
  }
`;

const MarqueeGrid = styled(GridWrapper)`
  grid-template-rows: max-content max-content auto max-content;
  z-index: 1;

  @media only screen and ${device.tablet} {
    grid-template-rows: max-content auto max-content;
  }

  @media only screen and ${device.laptop} {
    flex-direction: row;
    min-height: 42vw;
  }
`;

const Marquee = styled(Box)`
  background: ${({ theme }) => theme.colors.black};
  border-radius: calc(1rem + 2.5vw);
  border: 3px solid ${({ theme }) => theme.colors.golden};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* overflow: hidden; */

  .parallax-banner {
    border-radius: calc(0.8rem + 2.5vw);
    overflow: hidden;
  }

  &.marquee-main {
    grid-area: 1 / start / 2 / end;
    height: 70vw;
    min-height: 24rem;
    padding: 2vw;

    @media only screen and ${device.tablet} {
      height: 42vw;
      grid-area: 1 / start / 2 / col-start 9;
    }

    .h1 {
      font-size: ${clampBuilder(90 / 16, 450 / 16, 375, 2560)};
      line-height: 1;
    }

    .stary span,
    .classy span {
      width: 100%;
    }

    .stay {
      text-align: start;
    }

    .classy {
      text-align: end;
    }
  }

  &.marquee-main-bar {
    grid-area: 2 / start / 3 / end;
    height: 36vw;
    min-height: 20rem;

    @media only screen and ${device.tablet} {
      grid-area: 1 / col-start 9 / 2 / end;
      min-height: 24rem;
      height: 42vw;

      &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C13.6508 7.65218 12.3809 6.34783 10 0C7.61905 6.34783 6.3492 7.65218 0 10C6.3492 12.3478 7.61905 13.6522 10 20C12.3016 13.6522 13.6508 12.3478 20 10Z' fill='%23F5D171'/%3E%3C/svg%3E%0A");
        width: 20px;
        height: 20px;
        position: absolute;
        left: -1.03125rem;
        bottom: 0px;
        z-index: 10;
      }
    }
  }

  &.marquee-lower {
    grid-area: 4 / start / 5 / end;
    height: 70vw;
    min-height: 20rem;

    @media only screen and ${device.tablet} {
      grid-area: 3 / col-start 5 / 4 / end;
      min-height: 24rem;
      height: 32vw;

      &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C13.6508 7.65218 12.3809 6.34783 10 0C7.61905 6.34783 6.3492 7.65218 0 10C6.3492 12.3478 7.61905 13.6522 10 20C12.3016 13.6522 13.6508 12.3478 20 10Z' fill='%23F5D171'/%3E%3C/svg%3E%0A");
        width: 20px;
        height: 20px;
        position: absolute;
        left: -1.0625rem;
        top: 0px;
        z-index: 10;
      }
    }
  }

  &.marquee-lower-bar {
    grid-area: 5 / start / 6 / end;
    height: 70vw;
    min-height: 20rem;
    display: grid;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    gap: 2rem;

    @media only screen and ${device.tablet} {
      grid-area: 3 / start / 4 / col-start 5;
      min-height: 24rem;
      height: 32vw;
    }
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .image {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

const MarqueeButton = styled.div`
  grid-area: 3 / start / 4 / end;
  height: min-content;
  padding: 2px 0;

  @media only screen and ${device.tablet} {
    grid-area: 2 / start / 3 / end;
    padding: 3px 0;
  }

  .box,
  .button,
  > * {
    width: 100%;

    @media only screen and ${device.tablet} {
      height: 5.4375rem;
      font-size: 1rem;
    }
  }
`;

function MarqueeBox({ fluid, children, ...rest }) {
  return (
    <Marquee
      hiddenStyles={{ x: -10 }}
      transitionOverrides={{ duration: 0.1 }}
      {...rest}
    >
      <ParallaxBanner
        className="image-wrapper parallax-banner"
        layers={[
          {
            speed: -5,
            children: (
              <ImageBox
                className="image "
                hiddenStyles={{ x: 0, y: 0, scale: 1.1 }}
                transitionOverrides={{ duration: 3 }}
                loading="eager"
                fluid={fluid}
              />
            ),
          },
        ]}
      />
      {children}
    </Marquee>
  );
}

function HomepageMarqueeInner({
  data: {
    marquee_headline,
    marquee_headine_image,
    marquee_headline_image_aside,
    marquee_action_sub_text_image,
    marquee_action_text,
    marquee_action_sub_text,
    marquee_image,
  },
}) {
  return (
    <MarqueeSection role="marquee">
      <MarqueeGrid>
        <MarqueeBox
          className="marquee-main"
          hiddenStyles={{ x: -10 }}
          fluid={marquee_headine_image.fluid}
        >
          <Headline
            tag="h2"
            lineLength={0.65}
            aria-label={marquee_headline.text}
          >
            <AnimatedText
              preAnimate={{ x: "-0.2em" }}
              className="stay"
              text="Stay"
            />
            <AnimatedText
              preAnimate={{ x: "-0.2em" }}
              transitionOverrides={{ delay: 0.2 }}
              className="classy"
              text="Classy"
            />
          </Headline>
        </MarqueeBox>
        <MarqueeBox
          className="marquee-main-bar"
          hiddenStyles={{ x: -10 }}
          fluid={marquee_headline_image_aside.fluid}
          parallaxAmount={0.15}
        />
        <MarqueeButton className="button">
          <Box hiddenStyles={{ x: -10 }} transitionOverrides={{ delay: 0.35 }}>
            <Button
              onClick={() =>
                gtagClickEvent("form_inquiry_cta_marquee", "clicked")
              }
              color="black"
              to="/inquire"
            >
              {marquee_action_text}
            </Button>
          </Box>
        </MarqueeButton>
        <MarqueeBox
          className="marquee-lower"
          hiddenStyles={{ x: -10 }}
          transitionOverrides={{ delay: 0.55 }}
          fluid={marquee_image.fluid}
        />
        <MarqueeBox
          className="marquee-lower-bar"
          hiddenStyles={{ x: -10 }}
          transitionOverrides={{ delay: 0.55 }}
          fluid={marquee_action_sub_text_image.fluid}
          parallaxAmount={0.15}
        >
          <Headline
            size="h3"
            tag="h3"
            lineLength={0.65}
            aria-label={marquee_action_sub_text}
          >
            <AnimatedText text={marquee_action_sub_text} />
          </Headline>
          <SvgLoader logo="Monogram" />
        </MarqueeBox>
      </MarqueeGrid>
    </MarqueeSection>
  );
}

export function HomepageMarqueeNext({ data }) {
  return <HomepageMarqueeInner data={data} />;
}
