import * as React from "react";

const SvgComponent = ({ title, titleId, ...props }) => (
  <svg
    width={85}
    height={118}
    viewBox="0 0 85 118"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m43 117 1 .022 1-44L44 73l-1-.023v.011-.01L42 73l-1 .022 1 44 1-.022Z"
      fill="#F5D171"
    />
    <circle
      cx={42.5}
      cy={42.5}
      r={41.5}
      fill="#0F2707"
      stroke="#F5D171"
      strokeWidth={2}
    />
    <ellipse
      cx={42.5}
      cy={42.5}
      rx={41.5}
      ry={40.5}
      stroke="#F5D171"
      strokeWidth={2}
    />
    <path
      d="M23 21.123V21h13.343v.123c-3.14 1.228-3.728 3.686-3.728 7.74v33.54h19.278c6.61 0 8.22-3.101 11.018-8.49.303-.584.62-1.194.958-1.83H64L63.42 64H23v-.123c3.14-1.228 3.728-3.686 3.728-7.74V28.863c0-4.054-.588-6.512-3.728-7.74Z"
      fill="#F5D171"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.212 45.396c2.74 0 5.106-.932 5.106-3.534 0-2.583-2.385-3.516-5.106-3.516h-2.108v12.558h1.733v-5.508h.375Zm-.375-.613v-5.898h.058c2.125 0 3.56.753 3.56 2.977 0 2.225-1.435 2.921-3.56 2.921h-.058Z"
      fill="#F5D171"
    />
    <path
      d="M48.037 53.284c0 3.923 2.776 6.131 5.219 6.131 2.678 0 4.023-1.422 4.41-3.98h-.717c-.253 2.19-1.56 3.333-3.393 3.333-1.833 0-3.457-1.508-3.457-5.518s1.41-5.518 3.457-5.518c2.047 0 3.005 1.464 3.131 4.346h1.264c-.286-3.304-1.773-4.993-4.695-4.993-2.922 0-5.22 2.277-5.22 6.2Z"
      fill="#F5D171"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.043 38.153c2.847 0 5.012-1.036 5.012-3.363 0-2.182-2.048-3.044-4.33-3.099v-.166c1.931-.09 3.189-1.153 3.189-2.953 0-2.273-2.223-3.145-4.895-3.145h-2.08v12.726h3.104Zm-1.291-6.879v-5.232c2.242 0 3.368.457 3.368 2.53 0 2.363-1.14 2.702-2.974 2.702h-.394Zm.453.666c2.437 0 4.056.613 4.056 2.886 0 2.418-1.463 2.727-3.666 2.727h-.843V31.94h.453Z"
      fill="#F5D171"
    />
  </svg>
);

export default SvgComponent;
